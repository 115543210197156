<template>
  <div>
    <div class="text-right pb-2"></div>
    <div class="bg-colorWhite" style="border-radius: 20px">
      <b-row class="d-flex align-items-start justify-content-start py-1">
        <b-col md="8" class="px-3 py-1">
          <h3 class="font-weight-bolder text-colorBlack">Students List</h3>
          <p class="font-weight-bold text-colorGrey">
            All the Students who are under your supervision.
          </p>
        </b-col>
        
      </b-row>
      <b-card class="mx-2" style="border-radius: 20px">
        <b-table
          stacked="md"
          :fields="fields"
          :items="students"
          thead-class="tableHeadColor"
          small
          rounded
        >
          <template #head()="data">
            <div class="py-50">
              <span class="font-weight-bolder text-colorBlack">{{
                data.label
              }}</span>
            </div>
          </template>

          <template #cell(name)="row">
            <div class="py-1">
              <span
                class="font-weight-bold text-colorBlack m-0"
                style="font-size: 1rem"
              >
                <img
                  :src="row.item.image"
                  img-fluid
                  class="cursor-pointer"
                  width="30px"
                  height="30px"
                />
                {{ row.item.name ? row.item.name : "" }}
              </span>
            </div>
          </template>

          <template #cell(email)="row">
            <div class="py-1">
              <span
                class="font-weight-bold text-colorBlack m-0"
                style="font-size: 1rem"
              >
                {{ row.item.email ? row.item.email : "" }}
              </span>
            </div>
          </template>

          <template #cell(phone)="row">
            <div class="py-1">
              <span
                class="font-weight-bold text-colorBlack m-0"
                style="font-size: 1rem"
              >
                {{ row.item.phone ? row.item.phone : "" }}
              </span>
            </div>
          </template>


          <template #cell(nin)="row">
            <div class="py-1">
              <span
                class="font-weight-bold text-colorBlack m-0"
                style="font-size: 1rem"
              >
                {{ row.item.nin ? row.item.nin : "" }}
              </span>
            </div>
          </template>

          <template #cell(department)="row">
            <div class="py-1">
              <span
                class="font-weight-bold text-colorBlack m-0"
                style="font-size: 1rem"
              >
                {{ row.item.department_data.name ? row.item.department_data.name : "" }}
              </span>
            </div>
          </template>

          <template #cell(classes)="row">
            <div class="py-1">
              <span
                class="font-weight-bold text-colorBlack m-0"
                style="font-size: 1rem"
              >
                {{ row.item.classes_data.name ? row.item.classes_data.name : "" }}
              </span>
            </div>
          </template>
        </b-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
   
  },
  data() {
    return {
      fields: [
        { key: "name", label: "Student Name" },
        { key: "email", label: "Email" },
        { key: "phone", label: "Phone" },
        { key: "nin", label: "NIN" },
        { key: "department", label: "Department" },
        { key: "classes", label: "Class/Program" },
        // { key: "subjects", label: "Subjects" },
      ],      
      max: 20,
      students: [],
      student: '',
      currentPage: 1,
      perPage: 0,
      totalItems: 0,
    };
  },
  methods: {
    ...mapActions({
      getStudents: "appData/getStudents",
    }),

    async fetchPaginatedData() {
      try {
        const res = await this.getStudents({
          pageNumber: this.currentPage,
          name: this.name,
        });
        this.students = res.data.results;
        console.log(this.students);
        this.totalItems = res.data.count;
        this.perPage = res.data.per_page;
      } catch (error) {
        this.displayError(error);
      }
    },
  },

  watch: {
    currentPage: async function (val) {
      await this.fetchPaginatedData();
    },
  },
  async mounted() {
    await this.fetchPaginatedData();
  },
};
</script>

<style></style>
